import React, { useEffect, useState } from "react"

import { Modal, Button, Col, Row, Form } from "react-bootstrap"

import * as yup from "yup"
import { Formik, useFormik } from "formik"

import moment from "moment"
import API from "../../../common/API"

import { v4 as uuidv4 } from "uuid"
import { parsedUser } from "../../../common/GetCurrentUser"

import { useTranslation } from "react-i18next"
import CRUDField from "../../core/genericCRUD/CRUDField"
import AddEditEmployeeDTO from "./AddEditEmploye.dto"
import FieldSelector from "../../core/FieldSelector"
import YupSchema from "../../../common/YupSchema"
import yupTypeValidator from "../../../common/YupTypeValidator"
import { Base64ToFile } from "../../../common/helpers"
import { C } from "@fullcalendar/core/internal-common"

//Component
const AddEditEmployee = props => {
  const [isloading, setIsloading] = useState(false)
  const [fields, setFields] = useState<CRUDField[]>(AddEditEmployeeDTO)
  const [validationsSchema, setValidationSchema] =
    useState<yup.ObjectSchema<any>>()
  const [formikInitialObject, setFormikInitialObject] = useState<any>({})
  const { t } = useTranslation("global")
  const [state, setState] = useState<any>({
    UI: {
      Validated: false,
    },
    Employee: { name: "" },
    Positions: [],
    Banks: [],
  })

  const user = parsedUser()

  // METHODS
  const handleSubmit = async () => {
    try {
      let obj = formik.values
      debugger
      if (obj.id && obj.id.length === 0) {
        obj.id = uuidv4()
        obj.created = moment().format("YYYY-MM-DD HH:mm:ss")
      }
      debugger
      obj.organizationId = user.OrganizationId
      setIsloading(true)
      let request = await API.postAction("employees/AddEdit", obj)

      if (request.status === 200) {
        props.toggle(null, true)
      }
    } catch (e) {
      console.log(e)
    } finally {
      setIsloading(false)
    }
  }

  const formik = useFormik({
    initialValues: formikInitialObject,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: validationsSchema,
    onSubmit: () => handleSubmit(),
  })

  const updateField = (fieldName: string, fieldValue: any) => {
    console.log(fieldName, fieldValue)
    formik.setFieldValue(fieldName, fieldValue)
  }

  const fillFormikObject = (record: any) => {
    fields.forEach((item: CRUDField) => {
      if (item && item.name.length > 0) {
        //console.log("field name", item.name, "RecordValue ", record[item.name]);
        if (record && record[item.name]) {
          formik.setFieldValue(item.name, record[item.name])
        }
      }
    })
  }

  const prepareFormikObject = () => {
    const initialObject: { [key: string]: string } = {}
    const initialObjectValidation: { [key: string]: YupSchema } = {}

    if (fields) {
      fields.forEach((item: CRUDField) => {
        // if (item.FieldName === "Id" || item.FieldName === "File") return;

        initialObject[item.name] = item.value

        if (item.required) {
          const schema = yupTypeValidator(item)
          if (schema) {
            initialObjectValidation[item.name] = schema
          }
        }
      })
    }

    // formik.initialValues = initialObject;
    setFormikInitialObject(initialObject)
    setValidationSchema(yup.object().shape(initialObjectValidation))
  }

  const resultadosCedula = async (value: any) => {
    // formik.setFieldValue("cedula", value)
    let item = value[0]
    if (item) {
      formik.setFieldValue("firstName", item.nombres)
      formik.setFieldValue("lastName", item.apellido1)
      formik.setFieldValue("secondLastName", item.apellido2)
      formik.setFieldValue("birthDate", item.fechaNacimiento.substring(0, 10))
      if (item.sexo === "M") {
        formik.setFieldValue("gender", 1)
      } else {
        formik.setFieldValue("gender", 2)
      }
      formik.setFieldValue("identifierType", 1)

      if (item.imagen) {
        let file = Base64ToFile(
          "data:image/jpeg;base64," + item.imagen,
          item.cedula + ".jpg"
        )
        let bodyData = new FormData()
        bodyData.append("path", file)
        const request = await API.postAction(`File/Upload`, bodyData, {
          headers: { "Content-Type": "multipart/form-data" },
        })

        formik.setFieldValue("identifierPicture", request.data.response)
        // let bodyData = new FormData()
        // bodyData.append("path", file)
        // const request = await API.postAction(`File/Upload`, bodyData, {
        //   headers: { "Content-Type": "multipart/form-data" },
        // })
        // if (field.includes("photo")) setPhoto(request.data.response)
        // props.updateField(model.name, request.data.response)
      }
      // formik.setFieldValue("phone", item.phone)
      // formik.setFieldValue("position", item.position)
    }
    console.log("relatives", value)
  }

  // EVENTS
  useEffect(() => {
    //Filter fields based

    let finalFields: CRUDField[] = []
    fields.forEach((item: CRUDField) => {
      if (item.condition) {
        // console.log(
        //   "condition",
        //   item.condition,
        //   "organizationCountry='" + user.OrganizationCountry + "'",
        //   user
        // )
        if (
          item.condition ===
          "organizationCountry='" + user.OrganizationCountry + "'"
        ) {
          finalFields.push(item)
        }
      } else {
        finalFields.push(item)
      }
    })
    setFields(finalFields)
  }, [])
  useEffect(() => {
    if (fields) {
      prepareFormikObject()
    }
    if (props.model) {
      fillFormikObject(props.model)
    }
  }, [fields])

  return (
    <Modal show={props.show} onHide={props.toggle} className="modalRight">
      <Modal.Header closeButton>
        <Modal.Title>{t("Employee")}</Modal.Title>
      </Modal.Header>

      <Form onSubmit={formik.handleSubmit}>
        <Modal.Body>
          <Row>
            {fields &&
              fields.map((item, index) => (
                <FieldSelector
                  key={index}
                  model={item}
                  resultadosCedula={resultadosCedula}
                  updateField={updateField}
                  obj={formik.values}
                  value={formik.values[item.name as keyof typeof formik.values]}
                  errors={formik.errors}
                />
              ))}
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            type="submit"
            className="btn-sm"
            disabled={isloading}
          >
            {t("Save")}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default AddEditEmployee
